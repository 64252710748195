import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'


const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div className="text-center"><h1>404 Not Found</h1>
    <Link to='/'>Back to homepage</Link></div>
  </Layout>
)

export default NotFoundPage
